<template>
  <v-row
    id="login-background"
    class="d-flex align-md-center justify-md-center"
    :style="{ backgroundColor: globalConfig.backgroundLogin || '#F6F5F9' }"
  >
    <v-col cols="12">
      <v-container>
        <v-row>
          <v-col cols="12" md="7" class="d-flex justify-center align-md-center">
            <v-img
              class="responsive mx-5"
              contain
              max-height="91"
              max-width="365"
              :src="checkIfIsAlianza()"
            ></v-img>
          </v-col>
          <v-col cols="12" md="5">
            <v-card
              style="width: 100%; padding: 16px; border-radius: 20px"
              class="p-3"
            >
              <v-card-text>
                <h2 class="text-center my-4">
                  {{ globalConfig.title }}
                  <v-btn
                    v-if="isDev"
                    class="mx-2"
                    color="primary"
                    size="small"
                    dense
                    @click="configModal = true"
                  >
                    <v-icon> mdi-information-outline </v-icon>
                  </v-btn>
                </h2>
                <p class="subtitle text-center">{{ globalConfig.subTitle }}</p>
                <v-form ref="form" lazy-validation>
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Correo"
                    required
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Contraseña"
                    outlined
                    @click:append="show1 = !show1"
                  ></v-text-field>
                  <v-row v-if="globalConfig.showRemeberAccount">
                    <v-col cols="12" md="6">
                      <v-checkbox
                        v-model="rememberMe"
                        label="Recuérdame"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6" class="text-center text-md-right">
                      <a href="/recuperar-contrasenia" class="text-primary"
                        >¿Olvidaste tu contraseña?</a
                      >
                    </v-col>
                  </v-row>
                  <div style="display: flex; justify-content: center">
                    <v-btn
                      color="#00A7E4"
                      dark
                      width="236px"
                      height="48px"
                      class="rounded-xxl"
                      @click="validate"
                      style="margin-top: 30px"
                    >
                      Inicia sesión
                    </v-btn>
                  </div>
                  <v-alert v-if="error" class="mt-5" type="error">
                    La contraseña o el correo son incorrectos.
                  </v-alert>
                </v-form>
                <div v-if="globalConfig.showCreateAccount">
                  <div class="separator-container">
                    <!-- <div class="line"></div>
                    <span class="or-text">o</span>
                    <div class="line"></div> -->
                  </div>
                  <p style="color: #2e3b42; text-align: center">
                    ¿Aún no tienes una cuenta?
                    <a
                      style="font-weight: 600; color: #039ecc"
                      href="/crear-prospecto"
                    >
                      Regístrate
                    </a>
                  </p>
                  <!-- <v-btn
                    color="#039ecc"
                    block
                    dark
                    outlined
                    class="mr-4 rounded-xl"
                    @click="gocreateAccount"
                    style="margin-top: 30px"
                  >
                    Crear cuenta
                  </v-btn> -->
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <CommonTenentConfigModal
      :dialog="configModal"
      @closeModal="configModal = false"
    />
  </v-row>
</template>

<script>
import { axiosLogin } from "../../axiosLogin";
import { mainAxios } from "../../mainAxios";
import loginData from "../../helpers/data/data-login-template.json";
import LogoInter from "@/assets/interify.svg";
import { tenentConfig } from "@/helpers/tenent-config";
import CommonTenentConfigModal from "@/components/commonComponents/dialogs/CommonTenentConfigModal.vue";
import { postLog, getAreaNegocio, getRoles } from "@/services/log/log.service.js";
import grupoKC from "@/assets/alianzasLogos/grupoKC.png";
import betterware from "@/assets/alianzasLogos/betterware.png";
import concredito from "@/assets/alianzasLogos/concredito.png";
import finvivir from "@/assets/alianzasLogos/finvivir.svg";

export default {
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.login();
      }
    },
    login() {
      var qs = require("qs");
      var vm = this;
      vm.error = false;
      vm.cargando = true;
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: "admin",
          password: "g6PqgxyB0Z",
        },
      };
      var data = qs.stringify({
        grant_type: "password",
        username: this.email.trim(),
        password: this.password.trim(),
      });
      if (this.rememberMe) {
        localStorage.setItem("savedEmail", this.email.trim());
        localStorage.setItem("savedPassword", this.password.trim());
        localStorage.setItem("savedRememberMe", this.rememberMe);
      }

      axiosLogin
        .post("/oauth/token", data, config)
        .then((value) => {
          console.log({ value });
          localStorage.agenteAccessToken = value.data.access_token;
          const configA = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          this.getUser(configA);
          window.dataLayer.push({
            event: "ITFY_inicio",
            correo: this.email,
            boton_contacto: "Iniciar sesion",
          });
        })
        .catch((err) => {
          console.log(err);
          vm.error = true;
          vm.cargando = false;
          this.error = true;
        });
    },
    getRoles(configA) {
      mainAxios.get("/v1/agente/roles", configA).then((value) => {
        this.roles = value.data;
        this.changeView();
      });
    },
    getUser(configA) {
      axiosLogin.get("/user", configA).then((value) => {
        localStorage.id = value.data.principal.id;
        localStorage.agenteName =
          value.data.principal.nombre +
          " " +
          value.data.principal.apellidoPaterno;
        localStorage.agenteUuid = value.data.principal.uuid;
        localStorage.agenteMail = this.email;
        if (value.data.authorities[3].authority == "PROSPECTO") {
          this.roles = [
            {
              id: null,
              uuid: null,
              rol: "PROSPECTO",
              nombre: "Prospecto",
              canal: null,
              informa_a: "ADMINCC",
              nivel: null,
              notas: null,
              agente_id: null,
            },
          ];
          this.changeView();
        } else {
          this.getRoles(configA);
          this.loadUsuario();
        }
      });
    },
    changeView() {
      this.getAreaNegocio()
      var vm = this;
      localStorage.setItem("roles", JSON.stringify(this.roles));
      if (Object.keys(this.$route.query).length === 0) {
        switch (this.roles[0].rol) {
          case "ADMINCC":
          case "AGENTCCSPONSORS":
            vm.$router.push({ path: "/leads" });
            break;
          case "PROSPECTO":
            vm.$router.push({ path: "/inicio-prospectos" });
            break;
          case "AGENTCC":
            vm.$router.push({ path: "/leads" });
            break;
          default:
            vm.$router.push({ path: "/inicioVista" });
            break;
        }
      } else {
        vm.$router.push({ path: `${this.$route.query.from}` });
      }
    },
    gocreateAccount() {
      this.$router.push({ path: "/crear-cuenta" });
      window.dataLayer.push({
        event: "ITFY_inicio",
        correo: undefined,
        boton_contacto: "Crear cuenta",
      });
    },
    loadUsuario() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/agente/uuid/${localStorage.agenteUuid}`, config)
        .then((response) => {
          localStorage.id = response.data.id;
          localStorage.numero_colaborador =
            response.data.numero_colaborador ?? null;
          const array = [];
          localStorage.landings = array.concat(response.data.landings);
        });
    },
    async saveLoginLog(){
      const body = {
        agente_id: localStorage.id,
        actividad_id: 1,
        servicio_embebido: "",
        area_negocio_id: this.getActualAreaNegocio(),
      }
      await postLog(body);
    },
    async getRolesList() {
      this.roles = await getRoles();
      this.saveLoginLog()
    },
    async getAreaNegocio() {
      this.areaNegocio = await getAreaNegocio();
      this.getRolesList()
    },
    getActualAreaNegocio() {
      const currentRol = this.roles.find((e) => e.rol == this.roles[0].rol);
      const currentAreaNegocio = this.areaNegocio.find(
        (e) => e.id == currentRol.area_negocio
      );
      return currentAreaNegocio ? currentAreaNegocio.id : "";
    },
    checkIfIsAlianza() {
      if (typeof this.$route.params.nombre_empresa != "undefined") {
        switch (this.$route.params.nombre_empresa) {
          case "grupoKC":
            return this.grupoKC;
          case "betterware":
            return this.betterware;
          case "concredito":
            return this.concredito;
          case "finvivir":
            return this.finvivir;
          default:
            return this.globalConfig.logoUrl;
        }
      } else {
        return this.globalConfig.logoUrlLogin;
      }
    },
  },
  mounted() {
    this.email = localStorage.getItem("savedEmail") || "";
    this.password = localStorage.getItem("savedPassword") || "";
    this.rememberMe = localStorage.getItem("savedRememberMe") || false;
    window.dataLayer.push({
      event: "ITFY_inicio_v",
      no_cotizacion: undefined,
      userID: undefined,
      correo: undefined,
      telefono: undefined,
    });
    if (localStorage.getItem("globalConfig") == null) {
      var appliedConfig = tenentConfig();
      if (appliedConfig.isDevelop) {
        this.configModal = true;
      } else if (appliedConfig.applied) {
        this.globalConfig = JSON.parse(localStorage.getItem("globalConfig"));
        location.reload();
      }
    }
  },
  data() {
    return {
      cargando: false,
      email: "",
      emailRules: [(v) => !!v || "El correo es requerido"],
      show1: false,
      rules: {
        required: (value) => !!value || "Campo requerido",
        emailMatch: () => `The email and password you entered don't match`,
      },
      password: "",
      passwordRules: [(v) => !!v || "El password es requerido"],
      error: null,
      loginTemplate: loginData,
      rememberMe: false,
      roles: [],
      LogoInter: LogoInter,
      configModal: false,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
      areaNegocio: [],
      grupoKC: grupoKC,
      betterware: betterware,
      concredito: concredito,
      finvivir: finvivir,
      permitedAlianzas: ["grupoKC", "betterware", "concredito", "finvivir"],
    };
  },
  components: {
    CommonTenentConfigModal,
  },
  computed: {
    isDev() {
      if (window.location.hostname == "localhost") return true;
      else return false;
    },
  },
};
</script>

<style scoped>
#login-background {
  background-size: 32px 32px;
  background-color: #f6f5f9;
  background-repeat: repeat;
}
@media (max-width: 500px) {
  .responsive {
    max-width: 300px !important;
    max-height: 200px !important;
  }
}
.separator-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.line {
  flex: 1;
  height: 1px;
  background: linear-gradient(90deg, transparent, #000, transparent);
}

.or-text {
  margin: 0 10px;
  color: #000;
}
.subtitle {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat";
  color: #555555;
}
a {
  text-decoration: none;
  font-family: "Montserrat";
}
</style>
