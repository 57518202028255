<template>
  <v-container
    fluid
    class="ma-0 pb-5 pt-2 fill-screen"
    style="background-color: #001b30"
  >
    <v-row class="fill-height" justify="center" align="center">
      <v-col align-self="end" cols="12" sm="3" md="4" lg="4" xl="4">
        <v-img
          contain
          height="100"
          style="transform: rotate(180deg)"
          :src="FlechasAmarillas"
        ></v-img>
      </v-col>

      <v-col cols="12" class="card-container" sm="6" md="4" lg="4" xl="3">
        <v-card
          class="main-card pt-8 pb-5 pl-1 pr-1 mt-5 mb-2"
          justify="center"
          align="center"
          rounded="xl"
        >
          <v-img height="35" min-width="128" contain :src="LogoInter"></v-img>
          <div class="div" v-if="!cargando">
            <v-container v-if="step === 1">
              <v-row
                style=""
                class="d-flex flex-column mt-8"
                justify="center"
                align="center"
              >
                <v-col cols="12" md="11" class="pa-0">
                  <h2>Reestablece tu contraseña</h2>
                  <p>Introduce tu nueva contraseña.</p>
                  <v-text-field
                    v-model="localPassword"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Ingresa tu contraseña*"
                    outlined
                    dense
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="11" class="pa-0">
                  <v-text-field
                    v-model="repeatpassword"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.passwordMatch]"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Confirma tu contraseña*"
                    outlined
                    dense
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                justify="center"
                align="center"
                class="d-flex flex-column mt-0"
              >
                <v-col cols="12" class="ml-2 pa-2">
                  <v-checkbox
                    off-icon="mdi-check-circle"
                    on-icon="mdi-check-circle"
                    :value="isLengthValid"
                    :color="isLengthValid ? 'green' : 'grey'"
                    readonly
                    class="mb-negative-30"
                  >
                    <template slot="label">
                      <span class="text-validation"
                        >Debe incluir por lo menos 6 caracteres.</span
                      >
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" class="ml-2 pa-2">
                  <v-checkbox
                    off-icon="mdi-check-circle"
                    on-icon="mdi-check-circle"
                    :value="hasUppercase"
                    :color="hasUppercase ? 'green' : 'grey'"
                    readonly
                    class="mb-negative-30"
                  >
                    <template slot="label">
                      <span class="text-validation"
                        >Debe incluir letras mayúsculas y minúsculas.</span
                      >
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" class="ml-2 pa-2">
                  <v-checkbox
                    off-icon="mdi-check-circle"
                    on-icon="mdi-check-circle"
                    :value="hasNumber && hasSpecialChar"
                    :color="hasNumber && hasSpecialChar ? 'green' : 'grey'"
                    readonly
                    class="mb-negative-30"
                  >
                    <template slot="label">
                      <span class="text-validation"
                        >Debe incluir un número y un carácter especial.</span
                      >
                    </template>
                  </v-checkbox></v-col
                >
              </v-row>
              <v-col cols="12" align="center" class="mt-9">
                <v-btn
                  color="#00A7E4"
                  dark
                  class="rounded-xl"
                  block
                  @click="sendNewPassword"
                  :disabled="
                    !(
                      isLengthValid &&
                      hasUppercase &&
                      hasNumber &&
                      hasSpecialChar &&
                      hasPasswordIsEquals
                    )
                  "
                >
                  Continuar
                </v-btn>

                <a href="/" class="btn-back-login">
                  <v-icon left>mdi-arrow-left</v-icon>
                  Regresar al inicio de sesión
                </a>
              </v-col>
            </v-container>
            <CorreoRestablecido v-if="step === 2" />
          </div>
          <div v-else>
            <v-row
              style="
                width: 100%;
                padding: 16px;
                border-radius: 20px;
                min-height: 300px;
              "
              class="d-flex align-md-center justify-md-center"
            >
              <v-card-text class="text-center">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex align-md-center justify-md-center"
                  >
                    <v-img
                      class="responsive"
                      contain
                      max-height="150"
                      max-width="300"
                      :src="loginTemplate.createAccount"
                    ></v-img>
                  </v-col>
                  <v-col cols="12">
                    <h1>Enviando correo...</h1>
                    <span>Espere un momento por favor</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-row>
          </div>
          <v-img
            class="mt-6 mb-1 pa-0"
            height="26"
            min-width="80"
            :src="LogoInterBlack"
            contain
          ></v-img>
          <p
            class="ma-0 pa-0 mb-3"
            style="
              font-size: 10px;
              font-family: 'Montserrat';
              text-align: center;
              color: #6d6f71;
              font-weight: 500;
            "
          >
            Interify 2024 © Todos los Derechos Reservados
          </p>
        </v-card>
      </v-col>

      <v-col align-self="start" cols="12" sm="3" md="4" lg="4" xl="4">
        <v-img
          class="mt-8"
          contain
          height="100"
          :src="FlechasAmarillas"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogoInter from "@/assets/interify.svg";
import LogoInterBlack from "../../../assets/Logo_Interify_black.svg";
import FlechasAmarillas from "../../../assets/patron-amarillo-fondo.svg";
import CorreoRestablecido from "./CorreoRestablecido.vue";
import { mainAxios } from "../../../mainAxios.js";
export default {
  name: "RestablecerContrasenia",
  props: {},
  components: {
    CorreoRestablecido,
  },
  data() {
    return {
      cargando: false,
      step: 1,
      LogoInterBlack,
      FlechasAmarillas,
      LogoInter,
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "Campo requerido",
        emailMatch: () => `The email and password you entered don't match`,
        passwordMatch: (value) =>
          value === this.localPassword || "Las contraseñas no coinciden.",
      },
      repeatpassword: "",
      radioGroup: null,
      localPassword: "",
      token: "",
    };
  },
  methods: {
    sendNewPassword() {
      const config = {
        headers: {
          Authorization: "Bearer" + "7af112f1-963e-4c4b-bca9-50e45fccd00a",
          ContentType: "application/json",
        },
      };
      const data = {
        token: this.token,
        newPassword: this.repeatpassword,
      };
      mainAxios
        .post("/v1/prospecto/change-password-token", data, config)
        .then(({ data: { data, message } }) => {
          if (data.status == true && message == "Password changes") {
            this.step++;
          } else {
            console.error("Error al cambiar la contraseña");
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Error al cambiar la contraseña");
        });
    },
  },
  computed: {
    isLengthValid() {
      return this.localPassword.length >= 6 && this.localPassword.length <= 20;
    },
    hasUppercase() {
      return /[A-Z]/.test(this.localPassword);
    },
    hasNumber() {
      return /[0-9]/.test(this.localPassword);
    },
    hasSpecialChar() {
      return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(this.localPassword);
    },
    hasPasswordIsEquals() {
      return this.localPassword === this.repeatpassword;
    },
  },
  mounted() {
    this.token = this.$route.params.token;
    const config = {
      headers: {
        Authorization: "Bearer" + "7af112f1-963e-4c4b-bca9-50e45fccd00a",
      },
    };

    if (this.token) {
      mainAxios
        .get(`/v1/prospecto/valid-token-rp/${this.token}`, config)
        .then(({ data: { data } }) => {
          if (data.status == false) {
            this.$router.push("/token-no-valido");
          } else {
            console.log("token valido");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("No se encontró token en la URL.");
    }
  },
};
</script>
<style scoped>
.text-validation {
  color: #303030;
  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
}
.h3-password {
  font-weight: bold;
  color: #039ecc;
}
.mb-negative-30 {
  margin-bottom: -35px;
}
.fill-screen {
  min-height: 100vh;
  width: 100vw;
}

.fill-height {
  min-height: 100%;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-card {
  height: 100%;
}
h2 {
  font-size: 28px;
  font-weight: 700;
  font-family: "Raleway";
  color: #333333;
  text-align: left;
}
p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat";
  color: #6d6f71;
  text-align: left;
  margin-bottom: 30px;
  padding: 0;
}
.btn-back-login {
  color: #333333;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat";
  text-decoration: none;
  /* margin: 20px 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  margin-top: 35px;
  margin-bottom: 20px;
}
.div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
}
</style>
