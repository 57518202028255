<template>
  <v-card-text justify="left" align="left">
    <a href="/" class="btn-back mt-5">
      <v-icon left>mdi-arrow-left</v-icon>
      Regresar
    </a>
    <h2 class="mt-6" style="">Recuperación de contraseña</h2>
    <p class="mt-4 mb-8 pa-0">
      Introduce el correo electrónico con el que creaste tu cuenta, y te
      enviaremos las instrucciones para restablecer tu contraseña.
    </p>

    <v-form>
      <v-text-field
        required
        outlined
        dense
        label="Correo electrónico"
        :rules="emailRules"
        v-model="email"
      >
      </v-text-field>
      <v-btn
        class="pa-0"
        block
        color="primary"
        @click="sendEmail"
        :disabled="!allFieldsValidated()"
        >Continuar</v-btn
      >
    </v-form>
  </v-card-text>
</template>

<script>
export default {
  name: "InputCorreo",
  props: {
    email: String,
  },
  data() {
    return {
      emailRules: [
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!value || !value.length) {
            return "El campo es requerido";
          }
          return pattern.test(value) || "Dirección de correo incorrecta";
        },
      ],
    };
  },
  methods: {
    // nextStep() {
    //   this.$emit("isNextStep", true);
    // },
    sendEmail() {
      this.$emit("sendEmail", this.email);
    },
    allFieldsValidated() {
      const fieldsToCheck = [this.email];

      const validations = [this.emailRules[0](this.email)];

      return (
        fieldsToCheck.every((field) => !!field) &&
        validations.every(
          (validation) => validation === true || validation === ""
        )
      );
    },
  },
};
</script>
<style scoped>
.btn-back {
  color: #333333;
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 14px;
  margin-left: 0;
  font-weight: 300;
  display: flex;
  align-items: center;
}
h2 {
  font-size: 26px;
  color: #333333;
  line-height: 28px;
}
p {
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #6d6f71;
  line-height: 20px;
}
</style>
