<template>
  <v-card-text class="card-revisa">
    <h2>Tu contraseña se ha restablecido con éxito</h2>
    <p>Ingresa a tu cuenta con tu nueva contraseña.</p>
    <a href="/" class="btn-back-login">
      <v-icon left>mdi-arrow-left</v-icon>
      Regresar al inicio de sesión
    </a>
  </v-card-text>
</template>

<script>
export default {
  name: "CorreoRestablecido",
};
</script>
<style scoped>
h2 {
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  color: #333333;
  font-family: "Raleway";
  line-height: 38px;
}
p {
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  font-family: "Montserrat";
  line-height: 20px;
  padding: 0;
  margin-top: 8px;
}
.card-revisa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;

}
.btn-back-login {
  color: #333333;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat";
  text-decoration: none;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: -10px; */
}
</style>
