<template>
  <common-card>
    <v-row>
      <v-col>
        <v-row style="padding: 15px 20px 0px 20px;">
          <v-col cols="12" md="6">
            <h1>Alianzas Corporativas</h1>
          </v-col>
          <v-col cols="12" md="6" style="text-align: end; display: flex; flex-direction: row; align-items: center; justify-content: end;">
            <a href="/crear/alianza">Crear nueva alianza</a>
            <v-icon   class="mr-4 ml-2 " color="#029ECF">mdi-plus-circle-outline</v-icon>
            <!-- <v-btn
              class="common-botton"
              dark
              :loading="loading"
              @click="$router.push('/crear/alianza')"
            >
            <span>Crear nueva alianza</span>
            </v-btn> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-expansion-panels
      elevation="0"
      style="padding: 30px; border-radius: 20px"
    >
      <v-expansion-panel>
        <v-expansion-panel-header style="padding: 35px;">
          <h3 style="color:#42352E;">
            Búsqueda detallada
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                label="Buscar"
                hint="Buscar un agente por nombre, correo o RFC"
                class="mx-4 my-2"
                outlined
                dense
                color="primary"
                background-color="white"
                @keyup.enter="getAlianzas"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" style="text-align: end;">
              <v-btn
                class="common-botton"
                dark
                :loading="loading"
                @click="getAlianzas"
              >
                <span>Filtrar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div style="padding: 0px 40px 0px 40px;">
      <hr />
    </div>
    <v-row>
      <v-col cols="12" class="mb-10" style="padding: 40px">
        <v-data-table
          style=""
          :headers="agentesTable"
          :items="alianzas"
          item-key="name"
          class="elevation-0"
          :loading="loading"
          loading-text="Cargando..."
          no-data-text="Para ver elementos, primero realiza una búsqueda"
        >

          <template v-slot:item.sexo="{ item }">
            <div v-if="item.sexo == 1">Masculino</div>
            <div v-if="item.sexo == 2">Femenino</div>
          </template>
          <template #item.fechaRegistro="{item}">
            <span>{{ moment(item.fechaRegistro).format("DD/MM/YYYY") }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <div v-if="item.status == -1">
              <v-chip color="red" dark>Baja</v-chip>
            </div>
            <div v-if="item.status == 0">
              <v-chip color="blue" dark>Nuevo</v-chip>
            </div>
            <div v-if="item.status == 1">
              <v-chip color="green" dark>Activo</v-chip>
            </div>
            <div v-if="item.status == 2">
              <v-chip color="amber darken-2" dark>Inactivo</v-chip>
            </div>
          </template>
          
          <template v-slot:item.id="{ item }">
            <v-icon small class="mr-2" @click="goToAlianzaDashboard(item.id)">
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </common-card>
</template>

<script>
//import RfcFacil from 'rfc-facil';
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";
import CommonCard from "@/components/commonComponents/CommonCard.vue";

export default {
  components: {
    CommonCard,
  },
  data() {
    return {
      moment,
      agentes: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      search: "",
      agentesTable: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "razonSocial",
        },
        { text: "Fecha de Registro", value: "fechaRegistro" },
        { text: "Activo", value: "status" },
        { text: "Ver", value: "id" },
      ],
      alianzas:[]
    };
  },

 
  methods: {
    getAlianzas() {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      //Ruta por definir
      mainAxios.get(`/v1/alianza/list`, config).then((response) => {
          console.log(response)
          this.alianzas = response.data
          this.loading = false
        });
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
    goToAlianzaDashboard(item) {
      console.log(item);
      this.$router.push(`/alianza/perfil/${item}`);
    },
  },
  mounted() {
    this.getAlianzas();
  },
};
</script>
<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}
a{
  color: #029ECF;
  text-decoration: none;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
}
</style>
