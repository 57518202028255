<template>
  <v-container fill-height >
    <v-row justify="center" align="center" style="">
      <v-col cols="12" md="12" align="center" class="pa-0">
        <v-img max-height="36" max-width="142" :src="LogoInter"></v-img>
      </v-col>
      <v-col cols="6"  align="center" class="text-center mt-0" >
        <v-text
          style="font-family: Montserrat; font-size: 24px; font-weight: 700; width: 50px;"
        >
          ¡Has completado tu <br> registro exitosamente!
        </v-text>
      </v-col>
      <v-col cols="12" align="center" class="mt-5">
        <v-btn
          color="#00A7E4"
          dark
          class="rounded-xxl"
          width="231px"
          height="48px"
          @click="login"
        >
          Ir a mi portal
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogoInter from "@/assets/interify.svg";
export default {
  name: "SuccessPage",
  props: { password: String },
  data() {
    return {
      LogoInter,
    };
  },
  methods: {
    login() {
      this.$emit("login");
    },
  },

  mounted() {},
};
</script>
<style scoped></style>
