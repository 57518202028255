<template>
  <v-card-text class="card-revisa">
    <h2>Revisa tu correo</h2>
    <p>
      Te hemos enviado un correo con un enlace para restablecer tu contraseña.
      Si no lo encuentras en tu bandeja de entrada, revisa tu carpeta de correo
      no deseado.
    </p>
    <a href="/" class="btn-back-login">
      <v-icon left>mdi-arrow-left</v-icon>
      Regresar al inicio de sesión
    </a>
  </v-card-text>
</template>

<script>
export default {
  name: "RevisaCorreo",
};
</script>
<style scoped>
h2 {
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  color: #333333;
  font-family: "Raleway";
  line-height: 38px;
}
p {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  font-family: "Montserrat";
  line-height: 20px;
  padding: 0;
  margin-top: 8px;
}
.card-revisa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100vh;
  margin-top: 40px;
  /* padding: 0, 20px; */
}
.btn-back-login {
  color: #333333;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat";
  text-decoration: none;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
}
</style>
