<template>
  <v-container
    fluid
    class="ma-0 pb-0 pt-0 fill-screen"
    style="background-color: #001b30"
  >
    <v-row class="fill-height" justify="center" align="center">
      <v-col align-self="end" cols="4">
        <v-img
          contain
          height="100"
          style="transform: rotate(180deg)"
          :src="FlechasAmarillas"
        ></v-img>
      </v-col>
      <v-col justify="center" align="center" cols="4" class="card-container">
        <v-card
          class="main-card pt-6 pb-5 pl-1 pr-1 mt-5 mb-2"
          justify="center"
          align="center"
          rounded="xl"
        >
          <v-img height="35" min-width="128" contain :src="LogoInter"></v-img>
          <div class="main-container-input" v-if="!cargando">
            <InputCorreo
              v-if="step === 1"
              :email="email"
              @sendEmail="sendEmail"
            />
            <RevisaCorreo v-if="step === 2" />
          </div>
          <div v-else>
            <v-row
              style="
                width: 100%;
                padding: 16px;
                border-radius: 20px;
                min-height: 300px;
              "
              class="d-flex align-md-center justify-md-center"
            >
              <v-card-text class="text-center">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex align-md-center justify-md-center"
                  >
                    <v-img
                      class="responsive"
                      contain
                      max-height="150"
                      max-width="300"
                      :src="loginTemplate.createAccount"
                    ></v-img>
                  </v-col>
                  <v-col cols="12">
                    <h1>Enviando correo...</h1>
                    <span>Espere un momento por favor</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-row>
          </div>
          <v-img
            class="mt-6 mb-1 pa-0"
            height="26"
            min-width="80"
            :src="LogoInterBlack"
            contain
          ></v-img>
          <p
            class="ma-0 pa-0 mb-3"
            style="
              font-size: 10px;
              font-family: 'Montserrat';
              text-align: center;
              color: #6d6f71;
              font-weight: 500;
            "
          >
            Interify 2024 © Todos los Derechos Reservados
          </p>
        </v-card>
      </v-col>
      <v-col align-self="start" cols="4">
        <v-img
          class="mt-8"
          contain
          height="100"
          :src="FlechasAmarillas"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogoInter from "@/assets/interify.svg";
import LogoInterBlack from "../../../assets/Logo_Interify_black.svg";
import FlechasAmarillas from "../../../assets/patron-amarillo-fondo.svg";
import InputCorreo from "./InputCorreo.vue";
import RevisaCorreo from "./RevisaCorreo.vue";
import loginData from "../../../helpers/data/data-login-template.json";
import { mainAxios } from "../../../mainAxios";
export default {
  name: "RecuperarContrasenia",
  components: {
    InputCorreo,
    RevisaCorreo,
  },
  data() {
    return {
      LogoInter,
      LogoInterBlack,
      FlechasAmarillas,
      step: 1,
      email: "",
      cargando: false,
      loginTemplate: loginData,
      emailData: {},
    };
  },
  methods: {
    // isNextStep(isNext) {
    //   if (isNext) {
    //     this.step++;
    //     console.log(this.email);
    //   }
    // },
    sendEmail(email) {
      this.emailData = {
        email: email,
      };

      this.cargando = true;
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      mainAxios
        .post("/v1/prospecto/recovery-password", this.emailData, config)
        .then(({ data }) => {
          if (data.status === "SUCCEEDED") {
            this.cargando = false;
            this.step = 2;
            console.log(data.status);
          } else if (data.status === "FAILED") {
            this.cargando = false;
              this.$toast.warning(data.message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
          }
        });
    },
  },
};
</script>
<style scoped>
.fill-screen {
  min-height: 100vh;
  width: 100vw;
}

.fill-height {
  margin: auto;
  width: 90%;
}

.card-container {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-card {
  margin: auto;
  width: 80%;
}
.main-container-input {
  width: 90%;

}
</style>
