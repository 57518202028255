<template>
  <row class="container-no-token pl-10 pr-10">
    <v-col cols="7" class="justify-col">
      <h4>¡Oops!</h4>
      <h2>El enlace de recuperación de contraseña ha caducado.</h2>
      <p>
        Lo sentimos, pero este enlace ya no es válido. Por seguridad, los
        enlaces de recuperación de contraseña caducan después de un periodo
        determinado.
      </p>
      <v-row>
        <v-col cols="6">
          <v-btn height="58px" color="transparent"  @click="redirectToHome"> <v-icon class="mr-3">mdi-arrow-left</v-icon> Volver a inicio de sesión</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn height="58px" color="primary" @click="redirectToChangePassword">Solicitar un nuevo enlace</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="5" class="justify-col-img">
      <img :src="imgTokenNoValido" alt="Token no válido" />
    </v-col>
  </row>
</template>

<script>
import imgTokenNoValido from "../../../assets/tokenNoValido.svg";
export default {
  data() {
    return {
      imgTokenNoValido,
    };
  },
  name: "PantallaEnConstruccion",
  methods: {
    redirectToHome() {
      this.$router.push("/");
    },
    redirectToChangePassword() {
      this.$router.push("/recuperar-contrasenia");
    },
  },
};
</script>

<style scoped>
.container-no-token {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
h4 {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  text-align: left;
  color: #00a5df;
}
h2 {
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  text-align: left;
  color: #000000;
}
.justify-col-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.justify-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 20px;
}
img {
  width: 457px;
  height: 457px;
}
p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
</style>
