<template>
  <v-container>
    <v-row style="padding: 0px 30px 0px 30px">
      <v-col cols="12">
        <h1>Alta de alianza</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <v-stepper v-model="step" style="background-color: transparent">
          <v-stepper-header>
            <v-stepper-step class="stepper-style" :complete="step > 1" step="0">
              <div
                class="icon-text cursor-pointer"
                @click="isNextStep(false, 1)"
              >
                <v-icon color="#81CFE6" v-if="isStepCompleted(0)"
                  >mdi-album</v-icon
                >
                <v-icon color="#D9D9D9" v-else>mdi-circle-outline</v-icon>
                <span
                  :style="{ color: isStepCompleted(0) ? '#81CFE6' : '#C1C7CD' }"
                  >Registro de datos</span
                >
              </div>
            </v-stepper-step>
            <v-divider style="margin-bottom: 15px" color="#D9D9D9"></v-divider>
            <v-stepper-step class="stepper-style" :complete="step > 2" step="1">
              <div
                class="icon-text cursor-pointer"
                @click="isNextStep(false, 2)"
              >
                <v-icon color="#81CFE6" v-if="isStepCompleted(1)"
                  >mdi-album</v-icon
                >
                <v-icon color="#D9D9D9" v-else>mdi-circle-outline</v-icon>
                <span
                  :style="{ color: isStepCompleted(1) ? '#81CFE6' : '#C1C7CD' }"
                  >Carga de archivos</span
                >
              </div>
            </v-stepper-step>
            <v-divider style="margin-bottom: 15px" color="#D9D9D9"></v-divider>
            <v-stepper-step class="stepper-style" step="2">
              <div class="icon-text">
                <v-icon color="#81CFE6" v-if="isStepCompleted(2)"
                  >mdi-album</v-icon
                >
                <v-icon color="#D9D9D9" v-else>mdi-circle-outline</v-icon>
                <span
                  :style="{ color: isStepCompleted(2) ? '#81CFE6' : '#C1C7CD' }"
                >
                  Configuración de marca
                </span>
              </div>
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>

    <common-card>
      <div v-if="step===1">
        <v-row style="padding: 20px 30px 0px 30px">
          <v-col cols="12">
            <h2>Registro de datos</h2>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="center"
          style="padding: 0px 30px 20px 30px"
        >
          <v-col class="pa-0 pl-3" cols="3">
            <p>Nombre de la alianza</p>
          </v-col>
          <v-col class="pa-0 pr-3" cols="9"
            ><v-text-field
              name="nombreAlianza"
              label="Nombre alianza"
              :rules="generalRules"
              outlined
              primary
              dense
              color="#00a7e4"
            ></v-text-field
          ></v-col>
          <v-col class="pa-0 pl-3" cols="4">
            <p>¿A qué régimen perteneces?</p>
          </v-col>
          <v-col class="pa-0 pr-3" cols="8"
            ><v-autocomplete
              item-text="regimenFiscal"
              item-value="id"
              v-model="usuarioAgente.regimen"
              :items="regimen"
              :rules="generalRules"
              label="Régimen fiscal"
              outlined
              primary
              dense
              color="#00a7e4"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-if="mostrarPersonaMoral()" style="padding: 0px 30px 0px 30px">
          <v-col class="padding-col" cols="12" md="6">
            <v-text-field
              label="Razón Social"
              v-model="usuarioAgente.razonSocial"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="padding-col">
            <v-text-field
              id="rfc"
              v-model="usuario.rfcPersonaMoral"
              label="RFC"
              type="text"
              required
              placeholder="XAXX010101000"
              :rules="rfcRules"
              @input="onInputToUpper"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row style="padding: 0px 30px 0px 30px">
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              name="name"
              label="Nombre(s)"
              :rules="generalRules"
              v-model="usuario.nombre"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              name="apellidoPaterno"
              label="Apellido Paterno"
              :rules="generalRules"
              v-model="usuario.apellidoPaterno"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              name="apellidoMaterno"
              label="Apellido Materno"
              v-model="usuario.apellidoMaterno"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-autocomplete
              v-model="usuario.sexo"
              :items="sexoList"
              item-text="sexo"
              item-value="id"
              label="Género"
              required
              :rules="generalRules"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              v-model="usuario.fechaNacimiento"
              label="Fecha de Nacimiento"
              placeholder="dd/mm/aaaa"
              outlined
              primary
              color="#00a7e4"
              dense
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-autocomplete
              item-text="estadoRepublica"
              item-value="id"
              v-model="usuario.nacimientoEstadoId"
              :items="estados"
              :rules="generalRules"
              label="Estado de nacimiento"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              name="telefono"
              label="Teléfono  de  contacto"
              placeholder="##########"
              v-mask="'##########'"
              :rules="generalRules"
              v-model="usuario.telefono"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              label="Celular de contacto"
              placeholder="##########"
              v-mask="'##########'"
              v-model="usuario.celular"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>

          <v-col
            v-if="usuarioAgente.regimen != 'Persona Física'"
            cols="12"
            md="4"
            class="padding-col"
          >
            <v-text-field
              id="rfc"
              v-model="usuario.rfcRepresentanteLegal"
              label="RFC Representante legal"
              type="text"
              required
              placeholder="XAXX010101000"
              :rules="rfcRules"
              @input="onInputToUpper"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col v-else cols="12" md="4" class="padding-col">
            <v-text-field
              id="rfc"
              v-model="usuario.rfc"
              label="RFC"
              type="text"
              required
              placeholder="XAXX010101000"
              :rules="rfcRules"
              @input="onInputToUpper"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-autocomplete
              item-text="name"
              item-value="id"
              label="Estatus"
              v-model="usuario.status"
              :items="status"
              :rules="generalRules"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-autocomplete
              v-model="usuario.ramo"
              :items="ramoList"
              item-text="etiqueta"
              item-value="id"
              label="Ramo"
              multiple
              outlined
              primary
              color="#00a7e4"
              dense
              :rules="generalRules"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" v-if="index === 0">
                  <span>{{ item.etiqueta }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ usuario.ramo.length - 1 }} más)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-autocomplete
              v-model="usuario.canal"
              :items="canalList"
              label="Canal"
              item-text="etiqueta"
              item-value="id"
              outlined
              primary
              color="#00a7e4"
              dense
              :rules="generalRules"
            >
              <!-- <template v-slot:selection="{ item, index }">
              <v-chip color="primary" v-if="index === 0">
                <span>{{ item.etiqueta }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ usuario.canal.length - 1 }} más)
              </span>
            </template> -->
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-row v-for="(element, index) in usuario.emails" :key="index">
              <v-col class="padding-col" cols="12">
                <CommonEmailAutocomplete
                  @emailUpdate="(e) => (element.correo = e)"
                  :isDense="true"
                >
                </CommonEmailAutocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row style="padding: 0px 30px 0px 30px">
          <v-col cols="12">
            <!-- ONLY IF ROL = AGENTE -->
            <v-col cols="12" style="text-align: end">
              <v-btn
                @click="isNextStep(true)"
                class="common-botton mr-4"
                :disabled="validador"
                dark
              >
                Siguiente
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </div>
      <DocumentosAlianza v-if="step===2"/>
    </common-card>
  </v-container>
</template>
<script>
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";
import RfcFacil from "rfc-facil";
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import CommonEmailAutocomplete from "../../../../components/commonComponents/CommonEmailAutocomplete.vue";
import DocumentosAlianza from "./DocumentosAlianza.vue";
export default {
  components: {
    CommonCard,
    CommonEmailAutocomplete,
    DocumentosAlianza
  },
  data() {
    return {
      step: 1,
      prevStep: 1,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: [],
      showPassword: false,
      showPassword2: false,
      usuario: {
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        rfc: null,
        rfcPersonaMoral: null,
        rfcRepresentanteLegal: null,
        curp: null,
        password: null,
        confirmPassword: null,
        sexo: null,
        fechaNacimiento: null,
        rol: null,
        nacimientoEstadoId: null,
        celular: null,
        telefono: null,
        correo: null,
        emails: [
          {
            correo: "",
            favorito: true,
          },
        ],
        status: 0,
        motivoBaja: null,
        ramo: null,
        canal: null, // TODO cambiar catalogo o preguntar
        landing: null,
        vip: false,
        productos: [],
        turno: null,
        fecha_ingreso: null,
      },
      usuarioAgente: {
        fuenteIngreso: null,
        regimen: "Persona Física",
        asociadoPromotoria: null,
        tipoAgente: null,
        razonSocial: null,
        clabeBancaria: null,
        claveAgente: null,
        tipoCedula: null,
        nivelAgente: null,
        numeroCartera: null,
        cedula: null,
        rc: null,
        vigenciaCedula: null,
        vigenciaPolizaRC: null,
      },
      sexoList: [
        { id: 1, sexo: "Masculino" },
        { id: 2, sexo: "Femenino" },
      ],
      generalRules: [(v) => !!v || "Campo es necesario"],
      emailRules: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail no es valido",
      ],
      rfcRules: [
        (v) =>
          v.length === 10 ||
          v.length === 12 ||
          v.length === 13 ||
          "RFC debe tener 10, 12 o 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ],
      curpRules: [
        (v) => !!v || "CURP es necesario",
        (v) =>
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(
            v
          ) || "CURP no es valido recordar escribir en mayusculas",
      ],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
        { id: 33, estadoRepublica: "Desconocio" },
      ],
      statusItems: ["Nuevo", "Activo"],
      regimen: ["Persona Física", "Persona Moral"],
      canalList: ["Alianza"],
      ramoList: [],
      campaniaList: [],
      status: [
        { id: 1, name: "Prospecto Alianza" },
        { id: 2, name: "Nuevo" },
        { id: 3, name: "Activo" },
        { id: 4, name: "Inactivo" },
        { id: -1, name: "Baja" },
      ],
      canalItems: ["Agentes", "Directo", "Masivos"],
      regimenItems: ["Física", "Moral"],
      tipoAgenteItems: [
        "Agente",
        "Comisionista",
        "Agente Apoderado",
        "Agente Promotoria",
      ],
      idAgente: null,
    };
  },

  mounted() {
    this.listas();
    this.cargarRoles();
    window.dataLayer.push({
      event: "ITFY_crear_agente_v",
      no_cotizacion: undefined,
      asesor: localStorage.getItem("agenteMail"),
      correo: undefined,
      telefono: undefined,
    });
  },

  watch: {
    "usuario.fechaNacimiento"() {
      this.actualizarRfc();
    },
    "usuario.nombre"() {
      this.actualizarRfc();
    },
    "usuario.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "usuario.apellidoMaterno"() {
      this.actualizarRfc();
    },
  },

  computed: {
    validador() {
      let valdadorSum = 0;
      this.usuario.nombre
        ? this.usuario.nombre.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.apellidoPaterno
        ? this.usuario.apellidoPaterno.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.telefono
        ? this.usuario.telefono.length > 9
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.emails[0].correo
        ? this.usuario.emails[0].correo.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.nacimientoEstadoId
        ? this.usuario.nacimientoEstadoId.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.rfc
        ? this.usuario.rfc.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";

      if (valdadorSum == 5) {
        console.log("false");
        return false;
      } else {
        console.log("true");
        return true;
      }
    },
    currentRoles() {
      var currentRoles = [];
      var permitedRoles = [];
      switch (this.rol) {
        case "ADMIN":
          currentRoles = this.roles;
          break;
        case "ADMINAGENT":
          permitedRoles = ["MANAGER", "RECLUTA", "ADMINAGENT"];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        case "MANAGER":
          permitedRoles = ["AGENT"];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        case "ADMINCC":
          permitedRoles = [
            "AGENTCC",
            "AGENTCCSPONSORS",
            "MANAGERCC",
            "OPERADORCC",
            "RENOVACIONESCC",
          ];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        case "MANAGERCC":
          permitedRoles = [
            "AGENTCC",
            "AGENTCCSPONSORS",
            "OPERADORCC",
            "RENOVACIONESCC",
          ];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        case "OPERACIONES":
          permitedRoles = ["OPERADOR"];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        default:
          window.alert("No tienes permiso de crear usuarios");
          currentRoles = [];
          break;
      }
      return currentRoles;
    },
  },

  methods: {
    mostrarPersonaMoral() {
      if (this.usuarioAgente.regimen == "Persona Moral") {
        return true;
      } else {
        return false;
      }
    },
    isNextStep(isNext, value) {
      this.prevStep = this.step;
      if (isNext) {
        this.step++;
      } else if (value <= this.step) {
        this.step = value;
      }
    },
    isStepCompleted(stepNumber) {
      return this.step > stepNumber;
    },
    correoFavoritoModificado(v) {
      this.usuario.correo = v;
    },

    mostrarInputsAgente() {
      if (this.usuario.rol != "AGENT") {
        return false;
      } else return true;
    },
    cargarRoles() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/roles/list`, config).then((response) => {
        response.data
          ? response.data.map((element) => {
              this.roles.push({
                id: element.rol ? element.rol : "No tiene",
                nombre: element.nombre ? element.nombre : "No tiene",
              });
            })
          : "";
        this.roles.sort((a, b) => {
          const nombreA = a.nombre.toUpperCase();
          const nombreB = b.nombre.toUpperCase();

          if (nombreA < nombreB) {
            return -1;
          }
          if (nombreA > nombreB) {
            return 1;
          }
          return 0;
        });
      });
    },
    actualizarRfc() {
      if (
        !this.usuario.nombre ||
        !this.usuario.apellidoPaterno ||
        !this.usuario.apellidoMaterno ||
        !this.usuario.fechaNacimiento
      )
        return;
      const arregloNacimiento = moment(this.usuario.fechaNacimiento)
        .format("DD/MM/YYYY")
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.usuario.nombre,
        firstLastName: this.usuario.apellidoPaterno,
        secondLastName: this.usuario.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.usuario.rfc = rfc;
      this.usuario.rfcRepresentanteLegal = rfc;
      this.usuario.password = this.usuario.rfc;
    },
    setNewUser() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let dataUser = {
        nombre: this.usuario.nombre,
        apellidoPaterno: this.usuario.apellidoPaterno,
        apellidoMaterno: this.usuario.apellidoMaterno,
        sexo: this.usuario.sexo,
        fechaNacimiento: this.usuario.fechaNacimiento,
        nacimientoEstadoId: this.usuario.nacimientoEstadoId,
        telefono: this.usuario.telefono,
        celular: this.usuario.celular,
        correo: this.usuario.correo,
        rfc: this.usuario.rfc,
        curp: this.usuario.curp,
        password: this.usuario.password,
        rol: this.usuario.rol,
        status: 1,
        motivoBaja: this.usuario.motivoBaja ? this.usuario.motivoBaja : null,
        ramos: this.usuario.ramo,
        canales: this.usuario.canal,
        landings: this.usuario.landing ? this.usuario.landing : null,
        vip: this.usuario.vip,
        fuenteIngreso: this.usuarioAgente.fuenteIngreso
          ? this.usuarioAgente.fuenteIngreso
          : null,
        regimen: this.usuarioAgente.regimen ? this.usuarioAgente.regimen : null,
        agenteAsociadoPromotoria: this.usuarioAgente.asociadoPromotoria
          ? this.usuarioAgente.asociadoPromotoria
          : null,
        tipoAgente: this.usuarioAgente.tipoAgente
          ? this.usuarioAgente.tipoAgente
          : null,
        razonSocial: this.usuarioAgente.razonSocial
          ? this.usuarioAgente.razonSocial
          : null,
        clabeBancaria: this.usuarioAgente.clabeBancaria
          ? this.usuarioAgente.clabeBancaria
          : null,
        claveAgente: this.usuarioAgente.claveAgente
          ? this.usuarioAgente.claveAgente
          : null,
        tipoCedula: this.usuarioAgente.tipoCedula
          ? this.usuarioAgente.tipoCedula
          : null,
        nivelAgente: this.usuarioAgente.nivelAgente
          ? this.usuarioAgente.nivelAgente
          : null,
        numeroCartera: this.usuarioAgente.numeroCartera
          ? this.usuarioAgente.numeroCartera
          : null,
        cedula: this.usuarioAgente.cedula ? this.usuarioAgente.cedula : null,
        rc: this.usuarioAgente.rc ? this.usuarioAgente.rc : null,
        vigenciaCedula: this.usuarioAgente.vigenciaCedula
          ? moment(this.usuarioAgente.vigenciaCedula, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        vigenciaPolizaRc: this.usuarioAgente.vigenciaPolizaRC
          ? moment(this.usuarioAgente.vigenciaPolizaRC, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        fecha_ingreso: this.usuario.fecha_ingreso
          ? moment(this.usuario.fecha_ingreso, "DD/MM/YYYY").format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : null,
        turno: this.usuario.turno ? this.usuario.turno : null,
      };
      mainAxios
        .post(`/v1/agente/insert`, dataUser, config)
        .then(async (responseUsuario) => {
          this.idAgente = responseUsuario.data.id;
          console.log({ idAgente: this.idAgente });
          await this.$refs.listadoCorreos.actualizarCorreos();
          this.$toast.success("Usuario creado", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          setTimeout(() => {
            this.$router.push("/usuarios");
          }, 2000);
          window.dataLayer.push({
            event: "ITFY_crear_agente",
            sexo: this.usuario.sexo == 1 ? "Masculino" : "Femenino",
            fecha_nacimiento: this.usuario.fechaNacimiento
              .split("-")
              .reverse()
              .join("/"),
            telefono: this.usuario.telefono,
            correo: this.usuario.correo,
          });
        });

      // rol
      // mainAxios.post(`/v1/agente/rol`,this.usuario.rol,config).then((value) => {
      //   console.log(value)
      // })
    },

    listas() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      // TODO cambiar catalogo o preguntar
      // mainAxios.get(`/v1/canal/list`, config).then((response) => {
      //   this.canalList = response.data;
      // });
      mainAxios.get(`/v1/ramo/list`, config).then((response) => {
        this.ramoList = response.data;
      });
      mainAxios.get(`/v1/campania/list`, config).then((response) => {
        this.campaniaList = response.data;
      });

      mainAxios.get(`/v1/agente/landing/list`, config).then(({ data }) => {
        this.landings = data;
      });
    },
    onInputToUpper() {
      this.usuario.rfc = this.usuario.rfc.toUpperCase();
    },
  },
};
</script>

<style>
.mayusculas {
  text-transform: uppercase;
}
.cursor-pointer {
  cursor: pointer;
}
.icon-text {
  width: auto;
  color: #d9d9d9;
}

.stepper-style {
  padding: 0;
}
h2 {
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 700;
}
h1 {
  font-size: 26px;
  font-family: "Montserrat";
  font-weight: 700;
}
p {
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  padding: 0;
}
.padding-col {
  padding: 0px 12px 0px 12px;
}
</style>
