<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Estadísticas
    </template>
    <v-expansion-panel-content>
      <div style="padding: 30px;">
        <v-row>
          <v-col cols="12" md="7">
            <div class="d-md-flex">
              <span
                class="text-subtitle-1 font-weight-bold mt-1 mr-2"
                style="color: #4D5358"
                >Filtrar por periodo</span
              >
              <InputPeriodo
                @updateDate="getCotizaciones"
                style="min-width: 50%;"
              ></InputPeriodo>
            </div>
          </v-col>
        </v-row>
        <div v-if="!loading">
          <v-row v-if="!noResults">
            <v-col cols="12" md="6" xs="12" style="align-self: center;">
              <CardsInfoCotizaciones :cotizaciones="cotizaciones" />
            </v-col>
            <v-col cols="12" md="6">
              <CotizacionesStatusGraphic
                :cotizaciones="cotizaciones"
              ></CotizacionesStatusGraphic>
            </v-col>
          </v-row>
          <div v-else>
            <v-col
              cols="12"
              md="12"
              style="align-items: center; text-align: center; padding: 5%;"
            >
              <span class="titulo-header">Sin resultados que mostrar</span>
            </v-col>
          </div>
        </div>
        <div v-else>
          <v-row>
            <v-col>
              <v-col cols="12" class="d-flex align-md-center justify-md-center">
                <v-img
                  class="responsive"
                  contain
                  max-height="200"
                  max-width="300"
                  :src="loader.createAccount"
                ></v-img>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import CotizacionesStatusGraphic from "@/components/commonComponents/charts/CotizacionesStatusGraphic.vue";
import { mainAxios } from "@/mainAxios.js";
import CardsInfoCotizaciones from "./CardsInfoCotizaciones.vue";
import InputPeriodo from "../../../../../components/commonComponents/forms/InputPeriodo.vue";
import loginData from "@/helpers/data/data-login-template.json";

export default {
  components: {
    CommonExpansionPanel,
    CotizacionesStatusGraphic,
    CardsInfoCotizaciones,
    InputPeriodo,
  },

  data() {
    return {
      switch_data: 1,
      cotizaciones: {},
      menuFecha: false,
      form: {
        rango_fechas: "",
      },
      loader: loginData,
      noResults: false,
      loading: false,
    };
  },
  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
  },
  mounted() {
  },
  methods: {
    changeComponent(component) {
      this.switch_data = component;
      this.$emit("getData");
    },
    getCotizaciones(dates) {
      this.loading = true;
      
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let body={
        fechas: dates
      }
      if(String(window.location.pathname).includes('/alianza/perfil/')){
        body.alianza = parseInt(this.$route.params.alianza_id);
      }
      mainAxios.post(`/v1/cotizacion/tipo`,body, requestConfig).then(({ data }) => {
        this.cotizaciones = data;
        this.loading = false;
        this.noResults = false;
        if (this.cotizaciones.Unknown) delete this.cotizaciones.Unknown;
        if(Object.keys(this.cotizaciones).length == 0)
          this.noResults = true;
      });
    },
  },
  computed: {
    datesSelectedString() {
      if (this.form.rango_fechas.length > 1)
        return `${this.formatDate(
          this.form.rango_fechas[0]
        )} a ${this.formatDate(this.form.rango_fechas[1])}`;
      return "";
    },

    minimoUnCampoLleno() {
      return (
        Object.keys(this.form).filter(
          (e) => !!this.form[e] || this.form[e] === 0
        ).length >= 1
      );
    },
  },
};
</script>
