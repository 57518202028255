<template>
  <v-container>
    <v-col><h2>Carga de archivos solicitados Persona Física</h2></v-col>
    <v-row>
      <v-col class="download-container">
        <v-img class="img-download-docs" :src="Logomark"></v-img>
        <h3>Documentos</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-15" cols="6">
        <v-treeview
          :items="documents"
          activatable
          open-on-click
          :active.sync="activeDocument"
          @update:active="onDocumentSelect"
        >
          <template v-slot:prepend="">
            <v-list-item-content>
              <v-list-item-avatar class="mr-0">
                <img style="width: 24px" :src="bullet" alt="" />
              </v-list-item-avatar>
            </v-list-item-content>
          </template>
        </v-treeview>
      </v-col>

      <!-- Área de carga de archivos -->
      <v-col cols="6">
        <v-card-title class="documentTite">{{
          currentDocumentTitle
        }}</v-card-title>
        <v-card-text>
          <v-file-input
            v-model="selectedFiles[activeDocumentId]"
            label="Cargar archivo"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.ppt,.pptx"
            prepend-icon="mdi-upload"
            @change="onFileSelected"
            :error-messages="fileErrorMessage"
          ></v-file-input>
          <p>PDF, Word, Excel, PNG, JPG, JPEG y PPT. Tamaño máx 5 MB.</p>
        </v-card-text>
        <v-btn color="primary" @click="uploadFiles">Subir Archivos</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import bullet from "@/assets/bulletDocumentsAlianzas.svg";
import Logomark from "../../../../assets/Logomark.svg";

export default {
  data() {
    return {
      bullet: bullet,
      Logomark: Logomark,
      // Lista de documentos
      documents: [
        { id: 1, name: "ID vigente de Prospecto (ambos lados)", children: [] },
        { id: 2, name: "CURP", children: [] },
        { id: 3, name: "Constancia de Situación Fiscal", children: [] },
        { id: 4, name: "Comprobante de Domicilio", children: [] },
        { id: 5, name: "Caratula de Cuenta Bancaria", children: [] },
        { id: 6, name: "Opinión de cumplimiento fiscal", children: [] },
      ],
      activeDocument: [], // Documento seleccionado
      selectedFiles: {}, // Archivos seleccionados por documento
      fileErrorMessage: "", // Mensaje de error para la validación de archivos
    };
  },
  computed: {
    currentDocumentTitle() {
      const selectedDoc = this.documents.find((doc) =>
        this.activeDocument.includes(doc.id)
      );
      return selectedDoc ? selectedDoc.name : "";
    },
    activeDocumentId() {
      return this.activeDocument[0] || null;
    },
  },
  methods: {
    onDocumentSelect(newActiveDocument) {
      this.activeDocument = newActiveDocument;
      this.fileErrorMessage = "";
    },
    onFileSelected(file) {
      const maxFileSize = 5 * 1024 * 1024; // 5 MB en bytes
      const allowedExtensions = [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "png",
        "jpg",
        "jpeg",
        "ppt",
        "pptx",
      ];

      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        this.fileErrorMessage =
          "Tipo de archivo no permitido. Seleccione un archivo válido.";
        return;
      }

      if (file.size > maxFileSize) {
        this.fileErrorMessage = "El archivo supera el tamaño máximo de 5 MB.";
        return;
      } else {
        this.fileErrorMessage = "";
      }

      if (this.activeDocumentId) {
        this.$set(this.selectedFiles, this.activeDocumentId, file);
        console.log(
          "Archivo guardado para el documento ID:",
          this.activeDocumentId
        );
      }
    },
    async uploadFiles() {
      try {
        const formData = new FormData();

        for (const documentId in this.selectedFiles) {
          if (
            Object.prototype.hasOwnProperty.call(this.selectedFiles, documentId)
          ) {
            formData.append(
              `file_${documentId}`,
              this.selectedFiles[documentId]
            );
          }
        }

        const response = await axios.post("/tu-endpoint", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Archivos subidos con éxito:", response.data);
      } catch (error) {
        console.error("Error al subir los archivos:", error);
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.documentTite {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
}
.img-download-docs {
  max-width: 32px;
  max-height: 32px;
}
.download-container{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 36px;
    margin-top: 60px;
}
h3{
    cursor: pointer;

}
</style>
