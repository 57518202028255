import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/pages/Login.vue";
import Home from "../views/pages/Home.vue";
import HomeView from "../views/Home.vue";
import Terminos from "../views/pages/legales/Terminos.vue";
import Aviso from "../views/pages/legales/Aviso.vue";
import Cotiza from "../views/pages/Cotiza.vue";
import MisCotizaciones from "../views/pages/MisCotizaciones.vue";
import Proximamente from "../views/pages/Proximamente.vue";
import CotizaHub from "../views/pages/CotizaHub.vue";
import HubAdministrador from "../views/pages/administrador/HubAdministrador.vue";
import ReporteCotizaciones from "../views/pages/administrador/reportes/Cotizaciones.vue";
import ReporteCotizacionesAgentes from "../views/pages/administrador/reportes/CotizacionesAgentes.vue";
import ReporteEmisionesAgentes from "../views/pages/administrador/reportes/EmisionesAgentes.vue";
import ReporteClientes from "../views/pages/administrador/reportes/Clientes.vue";
import ReporteLeadsRastreator from "../views/pages/administrador/reportes/ReporteLeadsRastreator.vue";
import ReporteTuSeguroLeads from "../views/pages/administrador/reportes/TuSeguroLeads.vue";
import ReporteTuSeguroEmisiones from "../views/pages/administrador/reportes/TuSeguroEmisiones.vue";
import ReporteActualSales from "../views/pages/administrador/reportes/ActualSales.vue";
import ReporteAhorro from "../views/pages/administrador/reportes/Ahorro.vue";
import ReporteWse from "../views/pages/administrador/reportes/Wse.vue";
import EmisionesOffline from "../views/pages/administrador/reportes/EmisionesOffline.vue";
import EmisionesOfflineCallCenter from "../views/pages/administrador/reportes/EmisionesOfflineCallCenter.vue";
import CargaEmisiones from "../views/pages/administrador/reportes/CargaEmisiones.vue";
import Emite from "../views/pages/Emite.vue";
import Pago from "../views/pages/Pago.vue";
import EmisionDetalle from "../views/pages/emision/EmisionDetalle.vue";
import EditLead from "../views/pages/callCenter/EditLead.vue";
import ReporteLeads from "../views/pages/callCenter/ReporteLeads.vue";
import ReporteLeadsTuSeguro from "../views/pages/callCenter/ReporteLeadsTuSeguro.vue";
import ReporteCallCenter from "../views/pages/callCenter/ReporteCallCenter.vue";
import AbandonoLeads from "../views/pages/administrador/landings/ReporteAbandonoLeads.vue";
import AbandonoLeadsTuSeguro from "../views/pages/administrador/landings/ReporteAbandonoLeadsTuSeguro.vue";
//import EmisionDetalle from '../views/pages/callCenter/.vue';
import CotizacionDetail from "../views/pages/cotizacion/CotizacionDetail.vue";
import ConfirmaGNP from "../views/pages/ConfirmaGNP.vue";
import CrearUsuario from "../views/pages/administrador/usuarios/CrearUsuario.vue";
import MiCuenta from "../views/pages/administrador/usuarios/MiCuenta.vue";
import VisualizacionMiCuenta from "../views/pages/administrador/usuarios/VisualizacionMiCuenta.vue";
import VerAgentes from "../views/pages/administrador/usuarios/TablaUsuarios.vue";
import CotizaAgentes from "../views/pages/administrador/usuarios/CotizaComoAgente.vue";
import SolicitudForm from "../views/pages/solicitudes/SolicitudForm.vue";
import SeguimientoSolicitud from "../views/pages/solicitudes/SeguimientoSolicitud.vue";
import MisSolicitudesOperacion from "../views/pages/solicitudes/MisSolicitudesOperacion.vue";
import TarjetaComercial from "../views/pages/solicitudes/TarjetaComercial.vue";
import InfoRequeridaSolicitudes from "../views/pages/solicitudes/InfoRequeridaSolicitudes.vue";
import ActualizarSolicitud from "../views/pages/solicitudes/ActualizarSolicitud.vue";
import ActualizarEmision from "../views/pages/emision/ActualizarEmision.vue";
import CrearPoliza from "../views/pages/emision/CrearPoliza.vue";
// reeportees sgmm
import ReporteSGMM from "../views/pages/masivos/ReporteSGMM.vue";
import ReporteSuraSGMM from "../views/pages/masivos/ReporteSuraSGMM.vue";
import ReporteSuraMovilidad from "../views/pages/masivos/ReporteSuraMovilidad.vue";
// pagina whatsapp
import WhatsAppComponent from "../views/pages/whatsapp/WhatsAppComponent.vue";
//MODULO PRODUCTOS
import VistaListaProductos from "../views/pages/productos/VistaListaProductos.vue";
import VistaVisualizacionProductos from "../views/pages/productos/VistaVisualizacionProductos.vue";
import RegistroProductos from "../views/pages/productos/RegistroProductos.vue";
import EdicionProductos from "../views/pages/productos/EdicionProductos.vue";
//MODULO COMISIONES
import VistaListaComisiones from "../views/pages/comisiones/VistaListaComisiones.vue";
import VistaRegistroComisiones from "../views/pages/comisiones/VistaRegistroComisiones.vue";
//lista de clientes
import ClienteDetalle from "../views/pages/clientes/ClienteDetalle.vue";
import ActualizarClientes from "../views/pages/clientes/ActualizarClientes.vue";
//MODULO CLIENTES
import VistaListaClientes from "../views/pages/clientes/VistaListaClientes.vue";
import VistaVisualizacionClientes from "../views/pages/clientes/VistaVisualizacionClientes.vue";
import CrearClientes from "../views/pages/clientes/CrearClientes.vue";
import VistaEditarComisiones from "../views/pages/comisiones/VistaEditarComisiones.vue";
//NUEVOS REPORTES
import SolicitudesConPolizas from "../views/pages/administrador/reportes/SolicitudesConPolizas.vue";
import SolicitudesPendientesAprobacion from "../views/pages/administrador/reportes/SolicitudesPendientesAprobacion.vue";
import SlaSolicitudes from "../views/pages/administrador/reportes/SlaSolicitudes.vue";
import PolizasPorRenovar from "../views/pages/administrador/reportes/PolizasPorRenovar.vue";
import PolizasRenovadas from "../views/pages/administrador/reportes/PolizasRenovadas.vue";
import PolizasEmitidas from "../views/pages/administrador/reportes/PolizasEmitidas.vue";
import CobranzaPolizasActivas from "../views/pages/administrador/reportes/CobranzaPolizasActivas.vue";
import CuotasPagadas from "../views/pages/administrador/reportes/CuotasPagadas.vue";
import CuotasCanceladas from "../views/pages/administrador/reportes/CuotasCanceladas.vue";
import CuotasVencidas from "../views/pages/administrador/reportes/CuotasVencidas.vue";
import CarteraAgentes from "../views/pages/administrador/reportes/CarteraAgentes.vue";
import AtraccionTalento from "../views/pages/administrador/reportes/AtraccionTalento.vue";
//REPORTES LEADS TRACTOS (NUEVO)
import reporteLeadsTractos from "../views/pages/administrador/reportes/ReporteLeadsTractos.vue";
//NOTIFICACIONES
import NotificacionesPushCompletas from "../views/pages/notificaciones/NotificacionesPushCompletas.vue";
//SEGURO CELULAR
import LandingCelular from "../views/pages/cotizacion/LandingCelular.vue";
import CotizacionCelular from "../views/pages/cotizacion/CotizacionCelular.vue";
import MisCotizacionesCelular from "../views/pages/cotizacion/MisCotizacionesCelular.vue";
import CotizacionCelularDetail from "../views/pages/cotizacion/CotizacionCelularDetail.vue";
//Nuevo de Hub de comparadores de seguro
import HubCotizadores from "../views/pages/HubCotizadores.vue";
// import PruebasChart from '../views/pages/PruebasChart.vue';

// SEGURO MEDICO
import SeguroMedico from "../views/pages/cotizacion/medico/CotizacionSeguroMedico.vue";

// SEGURO VEHICULOS
import SeguroVehiculos from "../views/pages/cotizacion/vehiculos/CotizacionSeguroVehiculos.vue";
import SeguroVehiculosSecond from "../views/pages/cotizacion/vehiculos/SeguroVehiculosSecond.vue";
import VehiculosConfirmacion from "../views/pages/cotizacion/vehiculos/VehiculosConfirmacion.vue";

import ReporteCallback from "../views/pages/callCenter/CallbackTime.vue";
import ReporteTarificacion from "../views/pages/administrador/reportes/ReporteTarificacion.vue";
const LeadsSegurify = () => import("../views/pages/perfiles/leads/IndexLead.vue");
import ReporteCoruAig from "../views/pages/administrador/reportes/ReporteCoruAig.vue";
import CrearCuenta from "../views/pages/cuenta/CreacionCuenta.vue";
import CrearProspecto from "../views/pages/prospecto/CreacionProspecto.vue";
import CotizacionMascota from "../views/pages/cotizacion/mascota/CotizacionMascota.vue";
import ConfirmacionDatos from "../views/pages/cotizacion/mascota/ConfirmacionDatos.vue";
import CotizacionCelularNew from "../views/pages/cotizacion/celular/CotizacionCelular.vue";
import ConfirmacionDatosCelular from "../views/pages/cotizacion/celular/ConfirmacionDatosCelular.vue";
import EmitirCelular from "../views/pages/cotizacion/celular/EmitirCelular.vue";
import EmitirVehiculo from "../views/pages/cotizacion/vehiculos/EmitirVehiculo.vue";
const DashboardLeads = () => import("../views/pages/leads/DashboardLeads.vue");
const BuscarLead = () => import("../views/pages/leads/BuscarLead.vue");
const RecicleLead = () => import("../views/pages/recicleLead/RecicleLead.vue");
const PerfilProspecto = () =>
  import("../views/pages/perfiles/prospecto/IndexProspecto.vue");
const IndexUsuario = () =>
  import("../views/pages/perfiles/usuarios/IndexUsuario.vue");
import TablaProspectos from "../views/pages/administrador/prospectos/TablaProspectos.vue";
import InicioProspectos from "../views/pages/inicios/inicioProspectos.vue";
import MainHub from "../views/pages/hub/MainHub.vue";
import DetailsSolicitudesIndex from "../views/pages/hub/solicitudes/Details/DetailsSolicitudesIndex.vue";
import DetailsPolizasIndex from "../views/pages/hub/polizas/Details/DetailsPolizasIndex.vue";
import DetailsClienteIndex from "../views/pages/hub/clientes/Details/DetailsClienteIndex.vue";
import CreatePolizaIndex from "../views/pages/hub/polizas/Create/CreatePolizaIndex.vue";
const clientesEci = () => import("../views/pages/hub/clientes/Eci/ClientesEciDashboard.vue")
const ChatsLeads = () => import("../views/pages/leads/chats/ChatsLeadsIndex.vue");
const ChatsClientes = () => import("../views/pages/clientes/chats/ChatsClientesIndex.vue")
const PagoComisionesAplicados = () => import("../views/pages/administrador/reportes/PagoComisionesAplicados.vue");
const DispercionAsesores = () => import("../views/pages/administrador/reportes/DispercionAsesores.vue");
const CreateLeadPefai = () => import("../views/pages/leads/pefai/CreateLeadPefai.vue");
const CampaingsTable = () => import("../views/pages/administrador/campaigns/CampaingsTable.vue");
const CotizacionDetails = () => import("../views/pages/cotizacion/mascota/CotizacionDetails.vue");
const mascotaEmisionIndex = () => import("../views/pages/emision/mascota/mascotaEmisionIndex.vue");
const MascotaIframePago = () => import("../views/pages/emision/mascota/MascotaIframePago.vue");
const IndexReporteLlamadas = () => import("../views/pages/administrador/reportes/LlamadasInbound/indexReporteLlamadas.vue");

import TablaAlianzas from "../views/pages/administrador/alianzas/TablaAlianzas.vue";
import AltaAlianza from "../views/pages/administrador/alianzas/AltaAlianza.vue";
// import CrearAlianza from "../views/pages/administrador/alianzas/CrearAlianza.vue";
import AlianzasPerfilIndex from "../views/pages/alianzas/perfil/AlianzasPerfilIndex.vue";

import RecuperarContrasenia from "../views/pages/recuperarContraseniaProspectos/recuperarContrasenia.vue"
import RestablecerContrasenia from "../views/pages/recuperarContraseniaProspectos/RestablecerContrasenia.vue"
import TokenNoValido from "../views/pages/recuperarContraseniaProspectos/TokenNoValido.vue"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Login,
  },
  {
    path: "/alianza/:nombre_empresa",
    name: "LoginAlianza",
    component: Login,
  },
  {
    path: "/crear-prospecto",
    name: "crearProspecto",
    component: CrearProspecto,
  },
  {
    path: "/crear-cuenta",
    name: "crearCuenta",
    component: CrearCuenta,
  },
  {
    path: "/recuperar-contrasenia",
    name: "recuperarContrasenia",
    component: RecuperarContrasenia,
  },
  {
    path: "/cambio-contrasenia/:token",
    name: "cambioContrasenia",
    component: RestablecerContrasenia,
  },
  {
    path: "/token-no-valido",
    name: "tokenNoValido",
    component: TokenNoValido,
  },
  {
    path: "/inicioVista",
    name: "homeView",
    component: HomeView,
    redirect: "/inicio",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/inicio",
        name: "home",
        component: Home,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/mascota",
        name: "cotizacionMascota",
        component: CotizacionMascota,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/terminos-y-condiciones",
        name: "terminos",
        component: Terminos,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/aviso-de-privacidad",
        name: "aviso",
        component: Aviso,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotiza/:aseguradora",
        name: "cotiza",
        component: Cotiza,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/mascota/confirmar-datos/:mascota",
        name: "confirmarDatosMascota",
        component: ConfirmacionDatos,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/celular",
        name: "cotizacionCelular",
        component: CotizacionCelularNew,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/celular/confirmar-datos/:phone",
        name: "ConfirmacionDatosCelular",
        component: ConfirmacionDatosCelular,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/celular/emitir",
        name: "EmitirCelular",
        component: EmitirCelular,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/registroWhatsapp",
        name: "whatsapp",
        component: WhatsAppComponent,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotiza/:aseguradora/:uuid",
        name: "cotizaUuid",
        component: Cotiza,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotiza-hub",
        name: "cotiza-hub",
        component: CotizaHub,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/medico",
        name: "cotiza-medico",
        component: SeguroMedico,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/vehiculos",
        name: "cotiza-vehiculos",
        component: SeguroVehiculos,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/vehiculos/detalle",
        name: "detallesCotizacionVehiculo",
        props: true,
        component: () =>
          import("@/views/pages/cotizacion/vehiculos/DetallesCotizacion.vue"),
      },
      {
        path: "/cotizacion/vehiculos-second",
        name: "cotiza-vehiculos-second",
        component: SeguroVehiculosSecond,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/vehiculos-confirmacion",
        name: "cotiza-vehiculos-confirmacion",
        component: VehiculosConfirmacion,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/emitir-vehiculo/:carPrice",
        name: "emitir-vehiculo",
        component: EmitirVehiculo,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/mis-cotizaciones",
        name: "mis-cotizaciones",
        component: MisCotizaciones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/mis-emisiones",
        name: "mis-emisiones",
        component: () =>
          import("../views/pages/emisiones/dashboard/DashboardEmisiones.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/proximamente",
        name: "proximamente",
        component: Proximamente,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/home",
        name: "home-administrador",
        component: HubAdministrador,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-cotizaciones",
        name: "reporte-cotizaciones",
        component: ReporteCotizaciones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-cotizaciones-agentes",
        name: "reporte-cotizaciones-agentes",
        component: ReporteCotizacionesAgentes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-emisiones-agentes",
        name: "reporte-emisiones-agentes",
        component: ReporteEmisionesAgentes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-clientes",
        name: "reporte-clientes",
        component: ReporteClientes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-leads-rastreator",
        name: "reporte-leads-rastreator",
        component: ReporteLeadsRastreator,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-tuSeguro-leads",
        name: "reporte-tuseguro-leads",
        component: ReporteTuSeguroLeads,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-coru-aig",
        name: "reporte-coru-aig",
        component: ReporteCoruAig,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-tuSeguro-emisiones",
        name: "reporte-tuseguro-emisiones",
        component: ReporteTuSeguroEmisiones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-actualSales",
        name: "reporte-actualSales",
        component: ReporteActualSales,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-ahorro",
        name: "reporte-ahorro",
        component: ReporteAhorro,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-wse",
        name: "reporte-wse",
        component: ReporteWse,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-emisiones-offline",
        name: "reporte-emisiones-offline",
        component: EmisionesOffline,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callcenter/reporte-emisiones-offline",
        name: "reporte-emisiones-offlineCallCenter",
        component: EmisionesOfflineCallCenter,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/carga-emisiones",
        name: "carga-emisiones",
        component: CargaEmisiones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/editar-emision/:emision",
        name: "editar-emisiones",
        props: true,
        component: CargaEmisiones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/emite/:request",
        name: "emite",
        props: true,
        component: Emite,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/emite/:request/:uuid",
        name: "emiteUUID",
        props: true,
        component: Emite,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pago/:uuid",
        name: "pago-pre-emision",
        props: true,
        component: Pago,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/emision/vehiculo",
        name: "crearEmisionVehiculo",
        props: true,
        component: () =>
          import("@/views/pages/emision/vehiculos/CrearEmisionVehiculo.vue"),
      },
      {
        path: "/emision/:uuid",
        name: "emision",
        props: true,
        component: EmisionDetalle,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callCenter/lead",
        name: "searchLead",
        component: EditLead,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callCenter/reporteLeads",
        name: "reporteLeads",
        component: ReporteLeads,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callCenter/reporteLeadsTuseguro",
        name: "reporteLeadsTuSeguro",
        component: ReporteLeadsTuSeguro,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callCenter/reporteToquesCC",
        name: "reporteCC",
        component: ReporteCallCenter,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/callCenter/reporteCallBack",
        name: "reporteCallBack",
        component: ReporteCallback,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/landings/reporteAbandonoLeads",
        name: "ReporteAbandonoLeads",
        props: true,
        component: AbandonoLeads,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/landings/reporteAbandonoLeads/tuSeguro",
        name: "AbandonoLeadsTuSeguro",
        props: true,
        component: AbandonoLeadsTuSeguro,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizacion/:uuid",
        name: "cotizacion-detalle",
        props: true,
        component: CotizacionDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuario/crear",
        name: "crear-usuario",
        props: true,
        component: CrearUsuario,
        meta: {
          requiresAuth: true,
        },
      },
      //Editar info rápidamente desde el nav en "Mi Perfil"
      {
        path: "/usuario/micuenta",
        name: "micuenta",
        props: true,
        component: MiCuenta,
        meta: {
          requiresAuth: true,
        },
      },
      //Componente vista Visualizacion de usuario desde "Mi Perfil"
      {
        path: "/usuario/ver-micuenta",
        name: "verMiCuenta",
        props: true,
        component: IndexUsuario,
        meta: {
          requiresAuth: true,
        },
      },
      // Visualizacion de usuario desde "TablaUsuarios"
      {
        path: "/usuario/ver-micuenta/:uuid",
        name: "verCuentaUsuarios",
        props: true,
        component: IndexUsuario,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuario/ver-micuenta-viejo/:uuid",
        name: "verMiCuenta",
        props: true,
        component: VisualizacionMiCuenta,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuario/agente/:uuid",
        name: "agenteEdit",
        props: true,
        component: MiCuenta,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuarios/",
        name: "verUsuarios",
        props: true,
        component: VerAgentes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuarios/agentes",
        name: "verAgentes",
        props: true,
        component: VerAgentes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/usuario/CotizaComoAgente",
        name: "CotizaAgentes",
        props: true,
        component: CotizaAgentes,
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: '/landings/reporteAbandonoLeads',
      //   name: 'ReporteAbandonoLeads',
      //   props: true,
      //   component: AbandonoLeads,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: "/confirmaGNP/:ciane/confirma",
        name: "confirmaGNP",
        props: true,
        component: ConfirmaGNP,
      },
      {
        path: "/solicitudes",
        name: "solicitudes",
        props: true,
        component: () =>
          import(
            "../views/pages/solicitudes/dashboard/DashboardSolicitudes.vue"
          ),
      },
      {
        path: "/nueva-solicitud",
        name: "nueva-solicitud",
        props: true,
        component: SolicitudForm,
      },
      {
        path: "/seguimiento-solicitud/:uuid",
        name: "seguimiento-solicitud",
        props: true,
        component: SeguimientoSolicitud,
      },
      {
        path: "/solicitudes-operacion/:status",
        name: "solicitudes-operacion",
        props: true,
        component: MisSolicitudesOperacion,
      },
      {
        path: "/tarjeta-comercial",
        name: "tarjeta-comercial",
        props: true,
        component: TarjetaComercial,
      },
      {
        path: "/info-requerida/",
        name: "info-requerida",
        props: true,
        component: InfoRequeridaSolicitudes,
      },
      {
        path: "/actualizar-solicitud/:id",
        name: "actualizar-solicitud",
        props: true,
        component: ActualizarSolicitud,
      },
      {
        path: "/actualizar-emision/:id",
        name: "actualizar-emision",
        props: true,
        component: ActualizarEmision,
      },
      {
        path: "/crear-emision",
        name: "crear-emision",
        props: true,
        component: CrearPoliza,
      },

      // clientes modulo
      {
        path: "/lista-clientes",
        name: "lista-clientes",
        props: true,
        component: () =>
          import("../views/pages/clientes/dashboard/DashboardClientes.vue"),
      },
      {
        path: "/cliente-detalle/:uuid",
        name: "clienteDetalle",
        props: true,
        component: ClienteDetalle,
      },
      {
        path: "/actualizar-clientes/:id",
        name: "actualizar-clientes",
        props: true,
        component: ActualizarClientes,
      },
      {
        path: "/crear-clientes",
        name: "crear-clientes",
        props: true,
        component: CrearClientes,
      },

      {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },

      // reportes masivos
      {
        path: "/administrador/reporte-sgmm",
        name: "reporteSGMM",
        props: true,
        component: ReporteSGMM,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-sura-sgmm",
        name: "reporteSuraSGMM",
        props: true,
        component: ReporteSuraSGMM,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporte-sura-movilidad",
        name: "reporteSuraMovilidad",
        props: true,
        component: ReporteSuraMovilidad,
        meta: {
          requiresAuth: true,
        },
      },

      //Reporte de Leads Tractos(Nuevo)
      {
        path: "/administrador/reporte-leads-tractos",
        name: "reporteLeadsTractos",
        props: true,
        component: reporteLeadsTractos,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/administrador/reporteCallBack",
        name: "reporteCallBack",
        component: ReporteCallback,
        meta: {
          requiresAuth: true,
        },
      },
      //Modulo Productos
      {
        path: "/lista-productos",
        name: "listaProductos",
        props: true,
        component: VistaListaProductos,
      },
      {
        path: "/visualizacion-productos/:id",
        name: "visualizacionProductos",
        props: true,
        component: VistaVisualizacionProductos,
      },
      {
        path: "/edicion-productos/:id",
        name: "visualizacionProductosEditor",
        props: true,
        component: EdicionProductos,
      },
      {
        path: "/registro-productos",
        name: "registroProductos",
        props: true,
        component: RegistroProductos,
      },

      //Modulo Comisiones
      {
        path: "/lista-comisiones",
        name: "listaComisiones",
        props: true,
        component: VistaListaComisiones,
      },
      {
        path: "/visualizacion-comisiones/:uuid",
        name: "listaComisionesUUID",
        props: true,
        component: () =>
          import("@/views/pages/hub/cuotas/cuota/DetailsCuotaIndex.vue"),
      },
      {
        path: "/registro-comisiones",
        name: "registroComisiones",
        props: true,
        component: VistaRegistroComisiones,
      },
      //Modulo Cuotas
      {
        path: "/cuotas-hub",
        name: "cuotasHub",
        props: true,
        component: () => import("@/views/pages/hub/cuotas/CuotasHub.vue"),
      },
      //Modulo Comisiones
      {
        path: "/comisiones-hub",
        name: "comisionesHub",
        props: true,
        component: () => import("@/views/pages/hub/cuotas/CuotasHub.vue"),
      },
      //Modulo Clientes
      {
        path: "/lista-clientes",
        name: "listaClientes",
        props: true,
        component: VistaListaClientes,
      },
      {
        path: "/visualizacion-clientes/:uuid",
        name: "visualizacionClientes",
        props: true,
        component: VistaVisualizacionClientes,
      },
      {
        path: "/editar-comisiones/:uuid",
        name: "edicionComisiones",
        props: true,
        component: VistaEditarComisiones,
      },
      //NUEVOS REPORTES
      {
        path: "/solicitudes-con-polizas",
        name: "solicitudesConPolizas",
        props: true,
        component: SolicitudesConPolizas,
      },
      {
        path: "/solicitudes-pendientes-aprobacion",
        name: "solicitudesPendientesAprobacion",
        props: true,
        component: SolicitudesPendientesAprobacion,
      },
      {
        path: "/sla-solicitudes",
        name: "slaSolicitudes",
        props: true,
        component: SlaSolicitudes,
      },
      {
        path: "/polizas-por-renovar",
        name: "polizasRenovar",
        props: true,
        component: PolizasPorRenovar,
      },
      {
        path: "/polizas-renovadas",
        name: "polizasRenovadas",
        props: true,
        component: PolizasRenovadas,
      },
      {
        path: "/polizas-emitidas",
        name: "polizasEmitidas",
        props: true,
        component: PolizasEmitidas,
      },
      {
        path: "/cobranza-polizas-activas",
        name: "cobranzaPolizasActivas",
        props: true,
        component: CobranzaPolizasActivas,
      },
      {
        path: "/cuotas-pagadas",
        name: "cuotasPagadas",
        props: true,
        component: CuotasPagadas,
      },
      {
        path: "/cuotas-canceladas",
        name: "cuotasCanceladas",
        props: true,
        component: CuotasCanceladas,
      },
      {
        path: "/cuotas-vencidas",
        name: "cuotasVencidas",
        props: true,
        component: CuotasVencidas,
      },
      {
        path: "/cartera-agentes",
        name: "carteraAgentes",
        props: true,
        component: CarteraAgentes,
      },
      {
        path: "/atraccion-talento",
        name: "atraccionTalento",
        props: true,
        component: AtraccionTalento,
      },
      {
        path: "/leads-por-medio",
        name: "leadsPorMedio",
        props: true,
        component: () =>
          import("../views/pages/administrador/reportes/LeadsPorMedio.vue"),
      },
      {
        path: "/reporte/emisiones",
        name: "reporteGeneralEmisiones",
        props: true,
        component: () =>
          import("../views/pages/administrador/reportes/EmisionesGeneral.vue"),
      },
      {
        path: "/reporte/leads",
        name: "reporteLeads",
        props: true,
        component: () =>
          import("../views/pages/administrador/reportes/ReporteLeads.vue"),
      },
      {
        path: "/reporte/call-back-time",
        name: "reporteCBT",
        props: true,
        component: () =>
          import("../views/pages/administrador/reportes/ReporteCBT.vue"),
      },
      {
        path: "/reporte/marcacion",
        name: "reporteMarcacion",
        props: true,
        component: () =>
          import("../views/pages/administrador/reportes/ReporteMarcacion.vue"),
      },
      {
        path: "/reporte/marcacion-simple",
        name: "reporteMarcacionSimple",
        props: true,
        component: () =>
          import(
            "../views/pages/administrador/reportes/ReporteMarcacionSimple.vue"
          ),
      },

      //NOTIFICACIONES
      {
        path: "/notificaciones/:uuid",
        name: "notificaciones",
        props: true,
        component: NotificacionesPushCompletas,
      },

      //SEGURO CELULAR
      {
        path: "/landing-celular",
        name: "landingCelular",
        props: true,
        component: LandingCelular,
      },
      {
        path: "/inicio-celular/:identifier/emision/:emision_id",
        name: "landingCelular",
        props: true,
        component: LandingCelular,
      },
      {
        path: "/cotizacion-celular",
        name: "cotizacionCelular",
        props: true,
        component: CotizacionCelular,
      },
      {
        path: "/mis-cotizaciones-celular",
        name: "cotizacionCelular",
        props: true,
        component: MisCotizacionesCelular,
      },
      {
        path: "/cotizacion-celular-detail/:uuid",
        name: "cotizacionCelularDetail",
        props: true,
        component: CotizacionCelularDetail,
      },
      //Nuevo de Hub de comparadores de seguro
      {
        path: "/cotizacion-hub-seguros",
        name: "cotizacionHubSeguros",
        props: true,
        component: HubCotizadores,
      },
      {
        path: "/reporte-tarificacion",
        name: "reporteTarificacion",
        props: true,
        component: ReporteTarificacion,
      },
      {
        path: "/segurify/leads-segurify/lead/:uuid",
        name: "LeadsSegurify",
        props: true,
        component: LeadsSegurify,
      },


      // {
      //   path: '/pruebas-chart',
      //   name: 'pruebasChart',
      //   props: true,
      //   component: PruebasChart,
      // },

      {
        path: "/leads",
        name: "DashboardLeads",
        props: true,
        component: DashboardLeads,
      },
      {
        path: "/leads/buscar",
        name: "BuscarLead",
        props: true,
        component: BuscarLead,
      },
      {
        path: "/inbound",
        name: "servicioInbound",
        props: true,
        component: () =>
          import("../views/pages/leads/servicio_inbound/DashboardLeads.vue"),
      },
      {
        path: "/leads/recicle-lead",
        name: "RecicleLead",
        props: true,
        component: RecicleLead,
      },
      {
        path: "/perfil/prospecto",
        name: "PerfilProspecto",
        props: true,
        component: PerfilProspecto,
      },
      {
        path: "/perfil/prospecto/:prospecto_id",
        name: "PerfilProspectoAdmin",
        props: true,
        component: PerfilProspecto,
      },
      {
        path: "/lista/prospectos",
        name: "TablaProspectos",
        props: true,
        component: TablaProspectos,
      },
      {
        path: "/inicio-prospectos",
        name: "TablaProspectos",
        props: true,
        component: InicioProspectos,
      },
      {
        path: "/main-hub/:vista_id",
        name: "MainHub",
        props: true,
        component: MainHub,
      },
      {
        path: "/detalles-solicitud/:id",
        name: "detalles-solicitud",
        props: true,
        component: DetailsSolicitudesIndex,
      },
      {
        path: "/detalles-emision/:id",
        name: "detalles-emision",
        props: true,
        component: DetailsPolizasIndex,
      },
      {
        path: "/detalles-cliente/:uuid",
        name: "detalles-cliente",
        props: true,
        component: DetailsClienteIndex,
      },
      {
        path: "/poliza-crear",
        name: "poliza-crear",
        props: true,
        component: CreatePolizaIndex,
      },
      {
        path: "/solicitud-crear",
        name: "solicitud-crear",
        props: true,
        component: () =>
          import(
            "../views/pages/hub/solicitudes/Create/CreateSolicitudIndex.vue"
          ),
      },
      {
        path: "/clientes-eci",
        name: "clientes-eci",
        props: true,
        component: clientesEci,
      },
      {
        path: "/chats-leads",
        name: "chats-leads",
        props: true,
        component: ChatsLeads,
      },
      {
        path: "/chats-clientes",
        name: "chats-clientes",
        props: true,
        component: ChatsClientes,
      },
      {
        path: "/reporte-pago-comisiones-aplicados",
        name: "pago-comisiones-aplicados",
        props: true,
        component: PagoComisionesAplicados,
      },
      {
        path: "/reporte-dispercion-asesores",
        name: "dispercion-asesores",
        props: true,
        component: DispercionAsesores,
      },
      {
        path: "/crear-lead",
        name: "CreateLeadPefai",
        props: true,
        component: CreateLeadPefai,
      },
      {
        path: "/campaings/table",
        name: "CampaingsTable",
        props: true,
        component: CampaingsTable,
       },
       {
        path: "/mascota/cotizacion/details/:cotizacionGeneral/:planID",
        name: "CampaingsTable",
        props: true,
        component: CotizacionDetails,
       },
       {
        path: "/mascota/emision/details/:cotizacionGeneral/:planID",
        name: "CampaingsTable",
        props: true,
        component: mascotaEmisionIndex,
       },
       {
        path: "/mascota/pago/details/:cotizacionGeneral/:emision_id",
        name: "CampaingsTable",
        props: true,
        component: MascotaIframePago,
       },
       {
        path: "/reporte/llamadas-inbound",
        name: "IndexReporteLlamadas",
        props: true,
        component: IndexReporteLlamadas,
       },
       


      {
        path: "/administracion/alianzas",
        name: "tablaAlianzas",
        props: true,
        component: TablaAlianzas,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/crear/alianza",
        name: "CrearAlianza",
        props: true,
        component: AltaAlianza,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/alianza/perfil/:alianza_id",
        name: "AlianzasPerfilIndex",
        props: true,
        component: AlianzasPerfilIndex,
        meta: {
          requiresAuth: true,
        },
      },



    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const loginpath = window.location.pathname;
    if (localStorage.getItem("agenteAccessToken") == null) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
        query: { from: loginpath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
