<template>
  <v-container width-container>
    <v-row justify="center" align="center">
      <v-col cols="12" md="12" align="center" class="pa-0 mt-2">
        <v-img max-height="36" max-width="142" :src="LogoInter"></v-img>
      </v-col>
      <v-col cols="12" md="12" align="center">
        <v-text
          class="text-center mt-3 mb-4"
          style="font-family: 'Montserrat'; font-size: 16px; font-weight: 400"
        >
          Crea una cuenta y conviértete en un Asesor Digital interify
        </v-text>
      </v-col>
    </v-row>

    <v-row
      style="height: 140px"
      class="d-flex flex-column mt-8"
      justify="center"
      align="center"
    >
      <v-col cols="5" md="5" class="pa-0">
        <v-text-field
          v-model="localPassword"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Ingresa tu contraseña*"
          outlined
          dense
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
      <v-col cols="5" md="5" class="pa-0">
        <v-text-field
          v-model="repeatpassword"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.passwordMatch]"
          :type="show2 ? 'text' : 'password'"
          name="input-10-1"
          label="Confirma tu contraseña*"
          outlined
          dense
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" class="d-flex flex-column mt-0">
      <v-col cols="7"  class="ml-10 pa-2 ">
        <v-checkbox
          off-icon="mdi-check-circle"
          on-icon="mdi-check-circle"
          :value="isLengthValid"
          :color="isLengthValid ? 'green' : 'grey'"
          readonly
          class="mb-negative-30"
        >
          <template slot="label">
            <span class="text-validation"
              >Debe incluir por lo menos 6 caracteres.</span
            >
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="7" class="ml-10 pa-2 mt-6">
        <v-checkbox
          off-icon="mdi-check-circle"
          on-icon="mdi-check-circle"
          :value="hasUppercase"
          :color="hasUppercase ? 'green' : 'grey'"
          readonly
          class="mb-negative-30"
        >
          <template slot="label">
            <span class="text-validation"
              >Debe incluir letras mayúsculas y minúsculas.</span
            >
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="7"  class="ml-10 pa-2 mt-6 mb-6">
        <v-checkbox
          off-icon="mdi-check-circle"
          on-icon="mdi-check-circle"
          :value="hasNumber && hasSpecialChar"
          :color="hasNumber && hasSpecialChar ? 'green' : 'grey'"
          readonly
          class="mb-negative-30"
        >
          <template slot="label">
            <span class="text-validation"
              >Debe incluir un número y un carácter especial.</span
            >
          </template>
        </v-checkbox></v-col
      >
      <v-col cols="12" align="center" class="btn-confirm ">
        <v-btn
          color="#00A7E4"
          dark
          class="rounded-xl"
          width="150px"
          @click="insert"
          :disabled="
            !(
              isLengthValid &&
              hasUppercase &&
              hasNumber &&
              hasSpecialChar &&
              hasPasswordIsEquals
            )
          "
        >
          Siguiente
        </v-btn>
      </v-col>
      <v-col cols="12" align="center" class="mt-0">
        <v-tex class="">
          ¿Ya tienes una cuenta?
          <a style="font-weight: 600; color: #039ECC" href="/Login">
            Inicia sesión
          </a>
        </v-tex>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogoInter from "@/assets/interify.svg";
export default {
  name: "CrearContrasenia",
  props: { password: String },
  data() {
    return {
      LogoInter,
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "Campo requerido",
        emailMatch: () => `The email and password you entered don't match`,
        passwordMatch: (value) =>
          value === this.localPassword || "Las contraseñas no coinciden.",
      },
      repeatpassword: "",
      radioGroup: null,
      localPassword: this.password,
    };
  },
  methods: {
    insert() {
      this.$emit("insert", this.repeatpassword);
      window.dataLayer.push({
        event: "ITFY_datos_contraseña",
        boton_contacto: "Confirmar",
      });
    },
  },
  computed: {
    isLengthValid() {
      return this.localPassword.length >= 6 && this.localPassword.length <= 20;
    },
    hasUppercase() {
      return /[A-Z]/.test(this.localPassword);
    },
    hasNumber() {
      return /[0-9]/.test(this.localPassword);
    },
    hasSpecialChar() {
      return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(this.localPassword);
    },
    hasPasswordIsEquals() {
      return this.localPassword === this.repeatpassword;
    },
  },
  mounted() {
    // window.dataLayer.push({
    //   event: "ITFY_datos_contraseña_v",
    //   no_cotizacion: undefined,
    //   userID: undefined,
    //   correo: undefined,
    //   telefono: undefined,
    // });
  },
};
</script>
<style scoped>
.text-validation {
  color: #303030;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
}

.h3-password {
  font-weight: bold;
  color: #039ecc;
}
.mb-negative-30 {
  margin-bottom: -50px;
}
.width-container {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.btn-confirm {
  margin-top: 10px;
}

</style>
