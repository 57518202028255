<template>
  <div>
    <PhotoCard :personalData="personalData"/>
    <CardsInfoPanel
      :infoCards="infoCards"
      :routesConfigNoteCard="routesConfigNoteCard"
      :personalData="personalData"
      :emailRoutesConfig="emailRoutesConfig"
      @getData="getData"
    />
    <MainHub :isAlianza="false"/>
  </div>
</template>

<script>
import CardsInfoPanel from "../components/CardsInfoPanel.vue";
import PhotoCard from "../components/PhotoCard.vue";
import MainHub  from "../../hub/MainHub.vue";

export default {
  components: {
    CardsInfoPanel,
    PhotoCard,
    MainHub
  },

  data() {
    return {
      tab: 1,
      routesConfigNoteCard: {
        route: "",
        action: "POST",
      },
      emailRoutesConfig: {
        sendEmailRoute: "",
        action: "",
        optionCorreosList: [],
        destinatario: "",
      },
      personalData:{
        nombre:'Alianza No.1',
        apellidoPaterno:'',
        apellidoMaterno:'',
        id:'',
        nacimientoEstadoId:'1',
        status:'1'
      },
      infoCards: {
        notas: [],
        docs: [],
        emails: [],
      },
    };
  },

  methods: {
    getData() {
      return 1;
    },
  },
};
</script>
