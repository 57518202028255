<template>
  <div>
    <v-autocomplete
      v-model="correoElectronico"
      :label="customLabelText"
      :items="prediccionesCorreo"
      :filter="filtroCorreo"
      :rules="finalRules"
      :append-icon="null"
      outlined
      color="#00a7e4"
      :dense="isDense"
    ></v-autocomplete>
  </div>
</template>

<script>
import {
  dominiosComunes,
  validarDominioComun,
} from "@/helpers/validacionDominiosComunes.js";

export default {
  data() {
    return {
      busquedaCorreo: "",
      correoElectronico: null,
      rules: {
        emailRules: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !!value && value.length
              ? pattern.test(value) || "Dirección de correo incorrecta"
              : true;
          },
        ],
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
    };
  },
  props: {
    emailEdit: {
      type: String,
      default: "",
    },

    customLabelText: {
      type: String,
      default: "Correo Electrónico",
    },

    mustHasValue: {
      type: String,
    },
    isDense: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    correoElectronico(val) {
      this.$emit("emailUpdate", val);
    },
    emailEdit() {
      this.fillEmail();
    },
  },
  methods: {
    validarDominioComun,
    filtroCorreo(item, queryText, itemText) {
      this.busquedaCorreo = queryText;
      return itemText.includes(queryText);
    },
    fillEmail() {
      this.correoElectronico = this.emailEdit;
      this.busquedaCorreo = this.emailEdit;
    },
  },
  mounted() {
    this.fillEmail();
  },
  computed: {
    prediccionesCorreo() {
      if (!this.busquedaCorreo.length) return [""];
      if (this.busquedaCorreo.includes("@")) return [this.busquedaCorreo];
      return dominiosComunes.map((e) => `${this.busquedaCorreo}@${e}`);
    },

    finalRules() {
      const result = [
        ...this.rules.emailRules,
        this.validarDominioComun,
        ...this.requiredRule,
      ];

      if (this.mustHasValue)
        result.push(
          (v) => v == this.mustHasValue || "Los correos no coinciden"
        );

      return result;
    },
  },
};
</script>
